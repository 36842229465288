export const contact = [
  {
    name: "WhatsApp",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/whatsapp--v1.png",
    link: "https://wa.me/5543996340358?text=urlencodedtext",
  },
  {
    name: "Gmail",
    img: "https://img.icons8.com/glyph-neue/128/1A1A1A/gmail.png",
    link: "mailto:perinotolarissa@gmail.com",
  },
  {
    name: "Telegram",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/telegram.png",
    link: "https://t.me/larissaperinoto",
  },
];

export const socialMedia = [
  {
    name: "Linkedin",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/linkedin.png",
    link: "",
  },
  {
    name: "GitHub",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/github.png",
    link: "https://github.com/larissaperinoto",
  },
  {
    name: "Spotify",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/spotify.png",
    link: "https://open.spotify.com/user/22wksmrmnf6bbakqayxq3koli?si=a017584121724f4f",
  },
  {
    name: "VSCO",
    img: "https://img.icons8.com/ios/100/1A1A1A/vsco-logo.png",
    link: "https://vsco.co/-larissaperinoto",
  },
];
