import { useRef } from "react";
import BorderBottom from "./borderBottom";
import { projects } from "../utils/projects";
import Subtitle from "./subtitle";
import BottomLine from "./bottomLine";

function StackCard({ stack }) {
  return (
    <div className="py-[3px] px-[8px] font-mono text-sm font-medium border-2 border-black">
      {stack}
    </div>
  );
}

function Card({ name, description, thumb, stacks, github, deploy }) {
  return (
    <div className="w-full shadow-xl border-2 border-black shadown-md sm:w-1/2 lg:w-2/4">
      {thumb.map((img, index) => {
        return (
          <div
            key={index}
            className="w-full h-34 cursor-pointer xl:h-60 2xl:h-64"
          >
            <a href={deploy} target="_blank">
              <img src={img} className="w-full object-cover" />
            </a>
          </div>
        );
      })}
      <div className="px-4 ">
        <div className=" py-6 flex flex-col items-center justify-start">
          <h3 className=" pb-2 text-slate-800 font-semibold font-serif text-xl text-left underline">
            {name}
          </h3>
          <p className=" h-[40px] leading-[20px] overflow-hidden break-world text-ellipsis text-left font-mono">
            {description}
          </p>
        </div>
        <div className=" h-24 py-2 flex justify-center flex-wrap items-center gap-2">
          {stacks.map((stack, index) => (
            <StackCard key={index} stack={stack} />
          ))}
        </div>
        <div className=" py-4 flex justify-around item-center gap-4 lg:gap-2">
          <BorderBottom title="GitHub" link={github} />
          <BorderBottom title="Live" link={deploy} />
        </div>
      </div>
    </div>
  );
}

export default function Projects() {
  const carousel = useRef(null);

  function handleLeftClick(e) {
    e.preventDefault();
    carousel.current.scrollLeft -= carousel.current.offsetWidth;
  }

  function handleRightClick(e) {
    e.preventDefault();
    carousel.current.scrollLeft += carousel.current.offsetWidth;
  }

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <Subtitle text="Projects" style="text-4xl md:text-6xl" />
      <BottomLine style="mb-8" />

      <div className="w-[95%] h-full flex justify-center items-center gap-4">
        <button
          onClick={handleLeftClick}
          className="transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
        >
          &lt;
        </button>

        <div
          style={{ scrollBehavior: "smooth" }}
          className="w-[90%] gap-x-4 flex  overflow-x-auto"
          ref={carousel}
          /*  style={{ transform: `translateX(-${currentIndex * 100}%)` }} */
        >
          {projects.map(
            ({ name, description, thumb, stacks, github, deploy }, index) => (
              <Card
                key={index}
                name={name}
                description={description}
                thumb={thumb}
                stacks={stacks}
                github={github}
                deploy={deploy}
              />
            )
          )}
        </div>

        <button
          onClick={handleRightClick}
          className=" top-1/2 right-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
        >
          &gt;
        </button>
      </div>
    </div>
  );
}
