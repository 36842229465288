export default function TypingParagraph({ text, style }) {
  return (
    <p
      className={`animate-pulse text-center text-slate-800 font-thin font-serif world-wrap  truncate ${style}`}
    >
      {text}
      {/*  <span className="h-full border-r-4 animate-blink"></span> */}
    </p>
  );
}
