import SectionLayout from "./layouts/section";
import First from "./components/first";
import About from "./components/about";
import Nav from "./components/nav";
import Dropdown from "./components/dropdown";
import Projects from "./components/projects";
import Techs from "./components/techs";
import Contact from "./components/contact";

function App() {
  return (
    <div className="App">
      <main className="w-screen h-screen">
        <header className="bg-black w-full h-[70px] flex justify-end items-center">
          <Nav />
          <Dropdown />
        </header>
        <SectionLayout component={<First />} id="home" />
        <SectionLayout component={<About />} id="about" />
        <SectionLayout component={<Projects />} id="projects" />
        <SectionLayout component={<Techs />} id="techs" />
        <SectionLayout component={<Contact />} id="contact" />
        <footer className="py-4 bottom-0 border-t-2 border-black">
          <p className="w-full text-center font-mono text-slate-800">
            Developed By Larissa Perinoto.
          </p>
        </footer>
      </main>
    </div>
  );
}

export default App;
