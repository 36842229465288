import { contact, socialMedia } from "../utils/contact";
import BorderBottom from "./borderBottom";
import BottomLine from "./bottomLine";
import Subtitle from "./subtitle";
import TypingParagraph from "./typingParagraph";

function ContactContainer({ title, list }) {
  return (
    <div className="w-full my-4 flex flex-col justify-center items-center md:w-1/3">
      <Subtitle text={title} style="text-4xl" />
      <BottomLine />
      <div className="py-6 flex justify-around items-center gap-4">
        {list.map(({ name, img, link }, index) => {
          return (
            <a
              key={index}
              href={link}
              target="_blank"
              className="image-container relative"
            >
              <img width="50" height="50" src={img} alt={name} />
              <div className="tooltip">{name}</div>
            </a>
          );
        })}
      </div>
    </div>
  );
}

export default function Contact() {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div className="w-full py-6 flex justify-center items-center flex-col md:flex-row">
        <ContactContainer title="Contact" list={contact} />
        <ContactContainer title="Social Media" list={socialMedia} />
      </div>

      <p className="w-[90%] mb-10 text-center text-slate-800 font-thin font-mono text-lg md:w-1/3">
        <span className="font-semibold">Recruiter?</span> Make a CV request to
        know more about my skills and experience.
      </p>

      <BorderBottom
        title="Request CV"
        link="mailto:perinotolarissa@gmail.com?subject=cv request&body=Hello, I saw your website and I would like to know more about your professional background. My name is (insert your name here) and I work for the company (insert your company name here). Could you send me your CV?"
      />
      <TypingParagraph
        text="Let's develop solutions together?"
        style="text-2xl py-10 md:text-4xl"
      />
    </div>
  );
}
