import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { NavItem } from "./nav";

export default function Dropdown() {
  return (
    <nav className="inline-block md:hidden">
      <Menu>
        <MenuButton className="block text-slate-50 font-medium py-1 px-2 text-sm font-mono">
          <img
            width="30"
            height="30"
            src="https://img.icons8.com/ios-filled/50/FFFFFF/menu--v6.png"
            alt="menu--v6"
            className="mr-10"
          />
        </MenuButton>
        <MenuItems anchor="bottom" className="bg-black text-slate-200">
          <MenuItem>
            <NavItem name="About" link="#about" />
          </MenuItem>
          <MenuItem>
            <NavItem name="Projects" link="#projects" />
          </MenuItem>
          <MenuItem>
            <NavItem name="Techs" link="#techs" />
          </MenuItem>
          <MenuItem>
            <NavItem name="Contact" link="#contact" />
          </MenuItem>
        </MenuItems>
      </Menu>
    </nav>
  );
}
