import BorderBottom from "./borderBottom";
import TypingParagraph from "./typingParagraph";

export default function First() {
  return (
    <>
      <div className="w-full h-full text-center lg:w-1/2">
        <p className="pb-2 mt-[10%] text-slate-800 font-thin font-serif text-4xl lg:mt-[20%] xl:text-5xl 2xl:text-6xl">
          Hi there, my name is
        </p>
        <TypingParagraph
          text="Larissa Perinoto =]"
          style="text-4xl xl:text-5xl 2xl:text-6xl"
        />
        <p className="py-6 font-mono text-slate-800 text-xl lg:text-2xl xl:text-3xl lg:py-8 2xl:text-4xl 2xl:py-10">
          Software Engineer
        </p>
        <BorderBottom
          title={"Request CV"}
          link="mailto:perinotolarissa@gmail.com?subject=cv request&body=Hello, I saw your website and I would like to know more about your professional background. My name is (insert your name here) and I work for the company (insert your company name here). Could you send me your CV?"
          style="lg:text-xl xl:text-3xl xl:px-[50px] animate-slideInFromBottom"
        />
      </div>
      <div className="h-full w-full flex justify-center lg:w-1/2 lg:justify-start">
        <div className="w-[80%] mt-6 p-6  border-t-2 border-r-2 border-black animate-slideInFromRight md:w-[60%] lg:w-4/5">
          <div className="p-4 border-b-2 border-l-2 border-black flex justify-center">
            <img width="100%" height="auto" src="/profile.jpg" alt="Profile" />
          </div>
        </div>
      </div>
    </>
  );
}
