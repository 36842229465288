export const main = [
  {
    name: "HTML",
    img: "https://img.icons8.com/ios-glyphs/90/1A1A1A/html-5.png",
  },
  {
    name: "Javascript",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/javascript-logo.png",
  },
  {
    name: "Typescript",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/typescript.png",
  },
  {
    name: "Python",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/python.png",
  },
  {
    name: "Linux",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/linux.png",
  },
];

export const front = [
  {
    name: "React.Js",
    img: "https://img.icons8.com/external-tal-revivo-bold-tal-revivo/96/1A1A1A/external-react-a-javascript-library-for-building-user-interfaces-logo-bold-tal-revivo.png",
  },
  {
    name: "Angular",
    img: "https://img.icons8.com/external-tal-revivo-bold-tal-revivo/96/1A1A1A/external-angular-a-typescript-based-open-source-web-application-framework-logo-bold-tal-revivo.png",
  },
  {
    name: "Vue.Js",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/vuejs.png",
  },
  {
    name: "CSS",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/css3.png",
  },
  {
    name: "TailwindCSS",
    img: "https://img.icons8.com/material/90/1A1A1A/tailwind_css.png",
  },
];

export const back = [
  {
    name: "Express.Js",
    img: "https://img.icons8.com/ios/50/1A1A1A/express-js.png",
  },
  {
    name: "Nest.Js",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/lion-head.png",
  },
  {
    name: "RESTful API",
    img: "https://img.icons8.com/pastel-glyph/64/1A1A1A/api--v1.png",
  },
  {
    name: "Node.Js",
    img: "https://img.icons8.com/windows/96/1A1A1A/nodejs.png",
  },
  {
    name: "MongoDB",
    img: "https://img.icons8.com/external-tal-revivo-bold-tal-revivo/96/1A1A1A/external-mongodb-a-cross-platform-document-oriented-database-program-logo-bold-tal-revivo.png",
  },
  {
    name: "PostgreSQL",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/postgreesql.png",
  },
];

export const others = [
  {
    name: "Docker",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/docker.png",
  },
  {
    name: "Jest",
    img: "https://img.icons8.com/external-tal-revivo-bold-tal-revivo/96/1A1A1A/external-jest-can-collect-code-coverage-information-from-entire-projects-logo-bold-tal-revivo.png",
  },
  {
    name: "Git",
    img: "https://img.icons8.com/ios-filled/100/1A1A1A/git.png",
  },
];
