import { useState } from "react";
import { back, front, main, others } from "../utils/tecnologies";
import BottomLine from "./bottomLine";
import Subtitle from "./subtitle";

function Stack({ name, img }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="w-32 p-2 pb-1 flex flex-col justify-center items-center border-2 border-black cursor-pointer hover:bg-black hover:shadow-xl"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        className={`${isHovered ? "invert shadow-xl" : ""}`}
        width="45"
        height="45"
        src={img}
      />
      <p
        className={`${
          isHovered ? "text-white shadow-xl" : ""
        } text-slate-800 font-medium text-sm font-mono pt-4 pb-1`}
      >
        {name}
      </p>
    </div>
  );
}

function StacksContainer({ title, list }) {
  return (
    <div>
      <p className="pt-6 pb-4 text-center font-mono text-slate-800 underline text-xl">
        {title}
      </p>
      <div className="flex justify-center items-center flex-wrap gap-2">
        {list.map(({ name, img }, index) => (
          <Stack key={index} name={name} img={img} />
        ))}
      </div>
    </div>
  );
}

export default function Techs() {
  return (
    <>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <Subtitle text={"Technologies"} style="text-4xl md:text-6xl lg:mt-8 " />
        <BottomLine />
        <StacksContainer title="" list={main} />
        <StacksContainer title="Backend" list={back} />
        <StacksContainer title="Frontend" list={front} />
        <StacksContainer title="Others" list={others} />
      </div>
    </>
  );
}
