const links = [
  { name: "ABOUT", link: "#about" },
  { name: "PROJECTS", link: "#projects" },
  { name: "TECHS", link: "#techs" },
  { name: "CONTACT", link: "#contact" },
];

export function NavItem({ name, link }) {
  return (
    <a
      className="block text-slate-50 font-medium py-1 px-2 text-sm font-mono md:inline-block hover:bg-white hover:text-black transition ease-out duration-500"
      href={link}
    >
      {name}
    </a>
  );
}

export default function Nav() {
  return (
    <nav className="hidden md:inline-block pr-12">
      {links.map(({ name, link }) => (
        <NavItem key={name} link={link} name={name} />
      ))}
    </nav>
  );
}
