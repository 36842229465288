export default function BorderBottom({ style, title, link }) {
  return (
    <button
      className={`min-w-[120px] py-[10px] px-[25px] text-lg
        bg-black text-slate-50 font-mono font-medium border-2 border-transparent 
        hover:bg-white hover:text-black hover:border-black hover:shadow-xl 
        transition ease-out duration-500 ${style}`}
    >
      <a href={link} target="_blank">
        {title}
      </a>
    </button>
  );
}
