export default function SectionLayout({ component, id }) {
  return (
    <section
      className="min-h-screen flex flex-col-reverse justify-center items-center lg:flex-row "
      id={id}
    >
      {component}
    </section>
  );
}
