export const projects = [
  {
    name: "Vet de Casa",
    description:
      "Vet de casa services presentation page, veterinary service at home.",
    thumb: ["/projects/vet_de_casa/layer_1.png"],
    stacks: ["Vue.Js", "Vite.Js", "TailwindCSS"],
    github: "https://github.com/larissaperinoto/vet-de-casa-landingpage",
    deploy: "https://rafaelpescara.vercel.app/",
  },
  {
    name: "Schedules API",
    description: "Therapist appointment scheduling service.",
    thumb: ["/projects/schedules-api/layer_1.png"],
    stacks: ["Nest.Js", "Node.Js", "Docker", "Swagger", "PostgresSQL", "Jest"],
    github: "https://github.com/larissaperinoto/schedules-api",
    deploy: "",
  },
  {
    name: "Trivia",
    description:
      "Question and answer game where the user can register and their points are saved at the end of each game.",
    thumb: ["/projects/trivia/layer_1.png"],
    stacks: ["React.Js", "Redux", "CSS"],
    github: "https://github.com/larissaperinoto/trivia",
    deploy: "https://trivia-xi-seven.vercel.app/",
  },
  {
    name: "Nikel",
    description:
      "It controls the user's finances, allowing them to add financial inputs and outputs.",
    thumb: ["/projects/nikel/layer_1.png"],
    stacks: ["HTML", "Javascript", "Bootstrap"],
    github: "https://github.com/larissaperinoto/nikel",
    deploy: "https://larissaperinoto.github.io/nikel",
  },
];
