import BottomLine from "./bottomLine";
import Subtitle from "./subtitle";

function Paragraph({ text }) {
  return (
    <p className="py-4 text-center font-mono text-slate-800 font-thin text-lg leading-6">
      {text}
    </p>
  );
}

export default function About() {
  return (
    <>
      <div className="h-full w-[90%] flex flex-col justify-center items-center md:w-[50%]">
        <Subtitle text={"About me"} style="text-4xl md:text-6xl" />
        <BottomLine />
        <Paragraph
          text={`I'm 28 years old, I'm from Paraná, and I graduated from the
            Universidade Tecnológica Federal do Paraná. My experience with
            software development as a Full Stack Developer with a focus on
            Javascript allowed me to improve my knowledge of its most famous
            tools, such as React.js and Node.js.`}
        />
        <Paragraph
          text={` My work focused on Frontend allowed me to have strong skills in
            creating responsive design from prototypes in Figma, creating
            friendly interfaces that meet user's needs. Already focusing on
            Backend, I have a lot of knowledge in creating scalable solutions
            following RESTful architecture, in addition to data modeling. In my
            last professional experience I also had contact with DevOps
            practices such as microservices and container management.`}
        />
      </div>
    </>
  );
}
